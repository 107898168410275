import { Helper } from '../support/helper.support';
import { Audience } from './Interfaces/Audience';
import { SocialUser } from './Interfaces/SocialUser';

interface AudienceSentiments {
  POSITIVE: SentimentDetail;
  NEUTRAL: SentimentDetail;
  NEGATIVE: SentimentDetail;
}

interface SentimentDetail {
  count: number;
  prc: number;
}

class Media {
  public id: string;
  public media_type: string;
  public media_url: string;
  // public timestamp: string;
  public permalink: string;
  public caption: string;
  public like_count: number;
  public comments_count: number;
  public impressions: number;
  public reach: number;
  public engagement: number;
  // public impressions_feed: number;
  // public impressions_story: number;
}

export class AudienceReach {
  reach: number;
  reachIncrease: number;
  followersAccounts: number;
  nonFollowersAccounts: number;
  nonFollowersIncrease: number;
  impressions: number;
  posts: number;
  reels: number;
  stories: number;
  impressionsIncreasePerc: number;
  profileVisits: number;
  profileVisitsIncreasePerc: number;
  websiteVisits: number;
  websiteVisitsIncreasePerc: number;
  emails: number;
  emailsIncreasePerc: number;
  smsSent: number;
  smsSentIncreasePerc: number;
}
class Interaction {
  likes: number;
  comments: number;
  saved: number;
  increasePerc: number;
}
export class AudienceInteractions {
  posts: Interaction = new Interaction();
  reels: Interaction = new Interaction();
  stories: Interaction = new Interaction();
}
export class InsightImages {
  accountsReach: string;
  contentType: string;
  impressions: string;
  growth: string;
  countries: string;
  cities: string;
  genders: string;
  maleAges: string;
  femaleAges: string;
  interactions: string;
}

export class InstagramAudience extends Audience {

  mediaCount: number;
  follows: number;
  followers: number;
  startedFollowing: number;
  stoppedFollowing: number;
  dailyProfileViews: number;
  impressionsPerPost = 0;
  impressions = 0;
  impressionRate: number;
  engagementsPerPost = 0;
  engagementRate: number;
  reachPerPost = 0;
  reachRate: number;
  audienceCity: any[];
  audienceCountry: any[];
  audienceGender: any;
  gendersChartSeries: any;
  recentFeeds: Array<Media>;
  imagesToDisplay = new Array<string>();
  averageLikes = 0;
  averageComments = 0;
  unprocessedAudience: any;
  impressionsPerFeed: number;
  impressionsPerStory: number;
  impressionFeedRate: number;
  impressionStoryRate: number;
  hasAudience = false;
  reach: AudienceReach;
  interactions: AudienceInteractions;

  constructor(audienceJson: any) {
    super();
    if (!audienceJson) {
 return undefined;
}
    const audience = JSON.parse(audienceJson);
    this.unprocessedAudience = audience;
    this.mediaCount = audience.mediaCount;
    this.follows = audience.follows;
    this.followers = audience.followers;
    this.startedFollowing = audience.startedFollowing;
    this.stoppedFollowing = audience.stoppedFollowing;
    this.reach = audience.reach ? audience.reach : new AudienceReach();
    this.interactions = audience.interactions ? audience.interactions : new AudienceInteractions();
    this.impressionsPerFeed = Math.round(audience.IFRatio * this.followers);
    this.impressionsPerStory = Math.round(audience.ISRatio * this.followers);
    this.dailyProfileViews = audience.dailyProfileViews ? audience.dailyProfileViews : 0;
    this.setCitiesAudience(audience.audienceCity);
    this.setCountriesAudience(audience.audienceCountry);
    this.setGenderChart(audience.audienceGenderAge);
    this.audienceGender = audience.audienceGender;
    this.recentFeeds = audience.recentFeeds;
    this.setMetricsFromFeeds();
    return this;
  }


  // To use with insights requesting audience on demand
  public completeAudience(audience: any): InstagramAudience {
    this.dailyProfileViews = audience.dailyProfileViews ? audience.dailyProfileViews : 0;
    this.setCitiesAudience(audience.audienceCity);
    this.setCountriesAudience(audience.audienceCountry);
    this.setGenderChart(audience.audienceGenderAge);
    this.recentFeeds = audience.recentFeeds;
    this.setMetricsFromFeeds();
    return this;

  }

  public getSocialNetwork(): string {
    return 'instagram';
  }

  private setMetricsFromFeeds() {
    if (!this.recentFeeds) {
      this.recentFeeds = [];
      return;
    }
    this.recentFeeds.forEach( media => {
      this.impressionsPerPost += media.impressions;
      this.reachPerPost += media.reach;
      if (media.media_type === 'IMAGE' || media.media_type === 'CAROUSEL' || media.media_type === 'CAROUSEL_ALBUM') {
        this.imagesToDisplay.push(media.media_url);
      }
      this.averageLikes += media.like_count;
      this.averageComments += media.comments_count;
    });
    const mediaCount = this.recentFeeds.length;
    this.averageLikes = super.average(this.averageLikes, mediaCount);
    this.averageComments = super.average(this.averageComments, mediaCount);
    if (this.impressionsPerPost > 0) {
      this.impressionsPerPost = super.average(this.impressionsPerPost, mediaCount);
    } else {
      this.impressionsPerPost = super.average(this.reach.impressions, mediaCount);
    }
    this.engagementsPerPost = this.averageLikes + this.averageComments;
    this.engagementRate = this.followers > 0 ? super.ratio(this.engagementsPerPost, this.followers) : 0;
    if (this.reachPerPost > 0) {
      this.reachPerPost = super.average(this.reachPerPost, mediaCount);
    } else {
      this.reachPerPost = super.average(this.reach.reach, mediaCount);
    }
    this.reachRate = super.ratio(this.reachPerPost , this.followers);
    this.impressionRate = super.ratio(this.impressionsPerPost, this.followers);
    this.impressionFeedRate = this.impressionsPerFeed > 0 && this.followers > 0 && this.reach.impressionsIncreasePerc ?
      this.reach.impressionsIncreasePerc * (this.impressionsPerFeed / this.followers) : 0;
    this.impressionStoryRate = this.impressionsPerStory > 0 && this.followers > 0 && this.reach.impressionsIncreasePerc ?
      this.reach.impressionsIncreasePerc * (this.impressionsPerStory / this.followers) : 0;
    this.hasAudience = true;
  }

  parseAudice(data: {[key: string]: number}) {
    if (!data) {
      return null;
    }
    return Object.entries(data)
      .map(([name, value]) => [name, Number(value)])
      .sort(([nameA, valueA], [nameB, valueB]) => valueA > valueB ? -1 : 1)
      .slice(0, 5);
  }

  setCitiesAudience(cities: {[key: string]: number}): void {
    this.audienceCity = this.parseAudice(cities);
  }

  setCountriesAudience(countries: {[key: string]: number}): void {
    this.audienceCountry = this.parseAudice(countries);
  }

  setGenderChart(gender: any): void {
    if (!gender) {
      gender = {
        'M.13-17': 4.16,
        'M.18-24': 15.56,
        'M.25-34': 11.2,
        'M.35-44': 4.24,
        'M.45-54': 3.13,
        'M.55-64': 2.455,
        'M.65+': 0.7,
        'F.13-17': 4.66,
        'F.18-24': 22.8,
        'F.25-34': 19.04,
        'F.35-44': 9.19 ,
        'F.45-54': 2.06,
        'F.55-64': 0.505 ,
        'F.65+': 0.3
      };
    }
    this.gendersChartSeries = this.getGraphData(gender);
  }

  getGraphData(audienceGenderAge: {[key: string]: number }): GraphDataSeries[] {
    if (!audienceGenderAge) {
      return null;
    }
    const data = audienceGenderAge;
    const groupByAge: GraphDataSeries[] = [];
    Object.entries(data).forEach(([key, value]) => {
      const [gender, age] = key.split('.');
      let group: GraphDataSeries = groupByAge.find((item) => item.name == age);
      if (!group) {
        groupByAge.push(group = { name: age, series: [] });
      }
      group.series.push({ name: gender, value: Number(value)});
    });

    return groupByAge;
  }
}


interface GraphDataUnit {
  name: string;
  value: string | number;
};

interface GraphDataSeries {
  name: string;
  series: GraphDataUnit[];
};

export class Instagram implements SocialUser {
  audience: InstagramAudience = null;
  id: number;
  audienceSentiments: AudienceSentiments | null;
  businessAccountId: string;
  userId: number;
  bio: string;
  profilePicture: string;
  username: string;
  website: string;
  audienceLoaded = false;
  hasAudience: boolean;
  socialNetwork = 'instagram';
  engagementRate: number;
  reelPrice: number;
  storyPrice: number;
  feedPrice: number;
  avgFeed: number;
  avgReels: number;
  avgStories: number;
  avgReachFeed: number;
  avgReachReels: number;
  avgReachStories: number;
  avgEngagementFeed: number;
  avgEngagementReels: number;
  avgEngagementStories: number;

  constructor(rawInfo: any) {
    if (!rawInfo) {
      return;
    }
    this.id = rawInfo.id && parseInt(rawInfo.id);
    this.businessAccountId = rawInfo.business_account_id;
    this.userId = rawInfo.user_id && parseInt(rawInfo.user_id);
    this.bio = rawInfo.instagram_bio;
    this.profilePicture = rawInfo.instagram_profile_picture;
    this.username = rawInfo.instagram_user_name;
    this.website = rawInfo.instagram_website;
      this.audienceSentiments = null;
      if (rawInfo.hypeauditor_report_json) {
        const reportJson = JSON.parse(rawInfo.hypeauditor_report_json);
        const sentiments = reportJson.user.audience_sentiments;
        this.audienceSentiments = sentiments && sentiments.sentiments ? sentiments.sentiments : null;
      }
    this.audience = new InstagramAudience(rawInfo.audience_json);
    this.hasAudience = this.audience.hasAudience;
    this.audienceLoaded = !!this.audience;
    this.engagementRate = Math.round(100 * rawInfo.engagement_rate) / 100;
    this.reelPrice = Helper.numberOrNull(rawInfo.reel_price);
    this.storyPrice = Helper.numberOrNull(rawInfo.story_price);
    this.feedPrice = Helper.numberOrNull(rawInfo.feed_price);
    this.avgFeed = Helper.numberOrNull(rawInfo.avg_feed);
    this.avgReels = Helper.numberOrNull(rawInfo.avg_reels);
    this.avgStories = Helper.numberOrNull(rawInfo.avg_stories);
    this.avgReachFeed = Helper.numberOrNull(rawInfo.avg_reach_feed);
    this.avgReachReels = Helper.numberOrNull(rawInfo.avg_reach_reels);
    this.avgReachStories = Helper.numberOrNull(rawInfo.avg_reach_stories);
    this.avgEngagementFeed = Helper.numberOrNull(rawInfo.avg_engagement_feed);
    this.avgEngagementReels = Helper.numberOrNull(rawInfo.avg_engagement_reels);
    this.avgEngagementStories = Helper.numberOrNull(rawInfo.avg_engagement_stories);
  }

  public setAudience(rawInfo: any): void {
    this.audience.completeAudience(rawInfo);
    this.audienceLoaded = true;
  }

  goToProfile(): void {
    window.open('https://www.instagram.com/' + this.username, '_blank');
  }
}


import { SocialUser } from "./Interfaces/SocialUser";
import { Audience } from "./Interfaces/Audience";
import { Helper } from "../support/helper.support";

export interface AudienceSentiments {
  POSITIVE: SentimentDetail;
  NEUTRAL: SentimentDetail;
  NEGATIVE: SentimentDetail;
}

export interface SentimentDetail {
  count: number;
  prc: number;
}

export class TikTokAudience extends Audience {
  likes: number;
  following: number;
  followers: number;
  impressionsPerPost = 0;
  impressionRate: number;
  engagementRate: number;
  engagementsPerPost = 0;
  impressions = 0;
  averageLikes = 0;
  averagePlays = 0;
  recentMedia: Array<TikTokMedia> = [];
  hasAudience = false;
  mediaCount: number;
  averageShares: number;
  audienceCity: { [key: string]: number }; //-
  audienceCountry: { [key: string]: number };
  audienceGenderAge: { [key: string]: number };
  gendersChartSeries: any; //

  constructor(audienceJson: any) {
    super();
    if (!audienceJson) {
      return undefined;
    }
    const audience = JSON.parse(audienceJson);
    this.following = audience.following;
    this.followers = audience.followers;
    this.likes = audience.likes;
    this.mediaCount = audience.videoCount;
    this.recentMedia = audience.media;
    this.audienceGenderAge = audience.audienceGenderAge ?? null;
    this.audienceCountry = audience.audienceCountry ?? null;
    this.audienceCity = audience.audienceCity ?? null;
    this.gendersChartSeries = this.setGraphData();
    this.setMetricsFromFeeds();

    return this;
  }

  setGraphData(): GraphDataSeries[] {
    if (!this.audienceGenderAge) {
      return null;
    }
    const data = this.audienceGenderAge;

    const groupByAge: GraphDataSeries[] = [];
    Object.entries(data).forEach(([key, value]) => {
      const [gender, age] = key.split(".");
      let group: GraphDataSeries = groupByAge.find((item) => item.name == age);
      if (!group) {
        groupByAge.push((group = { name: age, series: [] }));
      }
      group.series.push({ name: gender, value });
    });

    return groupByAge;
  }

  public getSocialNetwork(): string {
    return "tiktok";
  }

  private setMetricsFromFeeds() {
    if (!this.recentMedia) {
      this.recentMedia = [];
      return;
    }
    this.recentMedia.forEach((media) => {
      this.impressionsPerPost += media.playCount;
      this.engagementsPerPost +=
        media.diggCount + media.commentCount + media.shareCount;
      this.averageLikes += media.diggCount;
      this.averagePlays += media.playCount;
    });
    const mediaCount = this.recentMedia.length;
    this.impressionsPerPost = super.average(
      this.impressionsPerPost,
      mediaCount
    );
    this.engagementsPerPost = super.average(
      this.engagementsPerPost,
      mediaCount
    );
    this.engagementRate = super.ratio(this.engagementsPerPost, this.followers);
    this.impressionRate = super.ratio(this.impressionsPerPost, this.followers);
    this.averageLikes = super.average(
      this.averageLikes,
      this.recentMedia.length
    );
    this.averagePlays = super.average(
      this.averagePlays,
      this.recentMedia.length
    );
    this.hasAudience = true;
  }
}

interface GraphDataUnit {
  name: string;
  value: string | number;
}

interface GraphDataSeries {
  name: string;
  series: GraphDataUnit[];
}

export class TikTok implements SocialUser {
  id: number;
  tiktokId: string;
  userId: number;
  signature: string;
  profilePicture: string;
  username: string;
  audience: TikTokAudience = null;
  audienceSentiments: AudienceSentiments | null;
  hasAudience = false;
  socialNetwork = "tiktok";
  isVerified: boolean;
  isRejected: boolean;
  engagementRate: number;
  videoPrice: number;
  avgVideoTiktok: number;
  avgReachVideoTiktok: number;
  avgEngagementVideoTiktok: number;

  constructor(rawInfo: any) {
    if (!rawInfo) {
      return;
    }
    this.id = rawInfo.id && parseInt(rawInfo.id);
    this.tiktokId = rawInfo.tiktok_id;
    this.userId = rawInfo.user_id && parseInt(rawInfo.user_id);
    this.signature = rawInfo.signature;
    this.profilePicture = rawInfo.profile_picture;
    this.username = rawInfo.username;
    this.audience = new TikTokAudience(rawInfo.audience);
    this.audienceSentiments = null;
    if (rawInfo.hypeauditor_report_json) {
      const reportJson = JSON.parse(rawInfo.hypeauditor_report_json);
      const sentiments = reportJson.report.features.audience_sentiments;
      this.audienceSentiments = sentiments && sentiments.data ? sentiments.data.sentiments : null;
    }
    this.hasAudience = this.audience.hasAudience;
    this.isVerified = !!rawInfo.is_verified;
    this.isRejected = !!rawInfo.is_rejected;
    this.engagementRate = Math.round(100 * rawInfo.engagement_rate) / 100;
    this.videoPrice = rawInfo.video_price;
    this.avgVideoTiktok = Helper.numberOrNull(rawInfo.avg_video_tiktok);
    this.avgReachVideoTiktok = Helper.numberOrNull(
      rawInfo.avg_reach_video_tiktok
    );
    this.avgEngagementVideoTiktok = Helper.numberOrNull(
      rawInfo.avg_engagement_video_tiktok
    );
  }

  goToProfile(): void {
    window.open("https://www.tiktok.com/@" + this.username, "_blank");
  }
}

class TikTokMedia {
  public id: number;
  public diggCount: number;
  public shareCount: number;
  public commentCount: number;
  public playCount: number;
  public embed: string;

  constructor(mediaObject: any) {
    this.id = TikTokMedia.getInt(mediaObject.id);
    this.diggCount = TikTokMedia.getInt(mediaObject.diggCount);
    this.shareCount = TikTokMedia.getInt(mediaObject.shareCount);
    this.commentCount = TikTokMedia.getInt(mediaObject.commentCount);
    this.playCount = TikTokMedia.getInt(mediaObject.playCount);
    this.embed = mediaObject.embed ?? "";
  }

  private static getInt(obj: any): number {
    if (!!obj && !isNaN(parseInt(obj, 10))) {
      return parseInt(obj, 10);
    }
    return 0;
  }
}

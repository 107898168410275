import { BrowserModule } from '@angular/platform-browser';
import { Inject, Injector, NgModule } from '@angular/core';
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { LoginModule } from './modules/login/login.module';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrandModule } from './modules/brand/brand.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { UiModule } from './ui/ui.module';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { CampaignModule } from './modules/campaign/campaign.module';
import { DatePipe } from '@angular/common';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { FormsModule } from '@angular/forms';
import { CompanyModule } from './modules/company/company.module';
import { ReportsModule } from './modules/reports/reports.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CreatorsModule } from './modules/creators/creators.module';
import { NotificationModule } from './modules/notification/notification.module';
import { initializeApp } from 'firebase/app';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { SuggestedPricesModule } from './modules/suggested-prices/suggested-prices.module';
import { MatIconModule } from '@angular/material/icon';
import { Creator } from './models/creator.model';
import { MatSnackBarModule } from '@angular/material/snack-bar';


initializeApp(environment.firebase);

export const HttpLoaderFactory = (http: HttpClient): TranslateHttpLoader => new TranslateHttpLoader(http);

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    DashboardModule,
    LoginModule,
    UiModule,
    NgxWebstorageModule.forRoot(),
    BrandModule,
    CampaignModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    FormsModule,
    CompanyModule,
    ReportsModule,
    SuggestedPricesModule,
    CreatorsModule,
    MatSnackBarModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NotificationModule,
    ScrollingModule,
    BrowserModule,
    FormsModule,
    MatIconModule
  ],
  providers: [
    NgbActiveModal,
    DatePipe,
    TranslateModule,
    Creator
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

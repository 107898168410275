import { Component, AfterViewInit, Input, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Submission } from '../../../../models/submission.model';
import { CreatorApproval } from '../../../../models/CreatorApproval.model';
import { Campaign } from '../../../../models/campaign.model';
import { BehaviorSubject } from 'rxjs';
import { NotificationSubject } from '../../../../models/notificationSubject.model';
import { NotificationService } from '../../../../services/notification.service';
import { MessagingService } from '../../../../services/messaging.service';
import { MatDialog } from '@angular/material/dialog';
import { SubmissionDialogComponent } from '../submission-dialog/submission-dialog.component';
import { SubmissionState } from '../../../../enum/submission-state.enum';
import { Unsubscriber } from '../../../../support/unsubscriber.support';
import { UserService } from '../../../../services/user.service';
import { Privilege } from '../../../../models/role.model';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CreateSubmissionComponent } from '../create-submission/create-submission.component';
import { SubmissionService } from 'src/app/services/submission.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-campaign-submissions',
  templateUrl: './campaign-submissions.component.html',
  styleUrls: ['./campaign-submissions.component.scss']
})
export class CampaignSubmissionsComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() public submissions: Array<Submission>;
  @Input() public creators: Array<CreatorApproval>;
  @Input() public campaign: Campaign;
  modalRef: NgbModalRef;
  public allOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public creatorSelected: CreatorApproval;
  private creatorSubmissions: Array<Submission>;
  public approvedCreators: Array<CreatorApproval>;
  private unsuscriber: Unsubscriber = new Unsubscriber();
  constructor(
    private readonly dialog: MatDialog,
    private readonly messagingService: MessagingService,
    private readonly cdref: ChangeDetectorRef,
    private readonly translateService: TranslateService,
    private readonly notificationService: NotificationService,
    private userService: UserService,
    private submissionService: SubmissionService
    ) {
  }

  public approved: Array<Submission>;
  public rejected: Array<Submission>;
  public pending: Array<Submission>;
  public updateRequired: Array<Submission>;

  public ngOnInit(): void {
    this.approvedCreators = this.creators.filter(x => x.status === 'Confirmed');
    this.creatorSubmissions = this.submissions;
    this.sortSubmissions();
  }

  public ngAfterViewInit(): void {
    this.redirectSubmissionDialog();
    this.listenNotification();
  }

  public ngOnDestroy(): void {
    this.unsuscriber.releaseSubscriptions();
  }

  private redirectSubmissionDialog(): void{
    const obs = this.messagingService
      .checkOpenCurrentSubmission()
        .subscribe({
          next:(data: any) => {
            if(data) {
              const submission: Submission = this.creatorSubmissions.find(sub => sub.id === parseInt(data.submissionId));
              this.creatorSelected = this.approvedCreators.find(creator => creator.creator.id === parseInt(data.creatorId));
              this.cdref.detectChanges();
              this.setSubmissionsForCreator(this.creatorSelected.id);
              switch(submission.status){
                case SubmissionState.Approved:
                  this.openSubmission(submission, this.approved);
                  break;
                case SubmissionState.Rejected:
                  this.openSubmission(submission, this.rejected);
                  break;
                case SubmissionState.Requested:
                  this.openSubmission(submission, this.pending);
                  break;
                case SubmissionState.UpdateRequired:
                  this.openSubmission(submission, this.updateRequired);
                  break;
                default:
                  break;
              }
            }
          }, error: (error: Error) => {
            console.log(error);
          }
        });
    this.unsuscriber.addSubscription(obs);
  }

  public userHasPrivileges(): boolean {
    return this.userService.hasPrivileges(
      [Privilege.PREDEFINED.EDIT_CONTENT], this.campaign.companyId, this.campaign.brandId, this.campaign.id);
  }

  public openSubmissionModal(): void {
    const dialog = this.dialog.open(CreateSubmissionComponent, {
      width: '600px',
      height : '750px',
      data: {
        campaign: this.campaign,
        approvedCreators: this.approvedCreators
      }
    });

    dialog.afterClosed().subscribe({
      next: async (submission: Submission) => {
        if (!submission) { return; }
        this.submissions.push(submission);
        this.creatorSubmissions = this.submissions;
        this.sortSubmissions();
      }, error: (error: Error) => {
        console.log(error);
      }
    });
  }

  private listenNotification(): void {
    this.notificationService
      .listenNotifications()
        .subscribe({
          next: (notification: NotificationSubject) => {
            const newNotification = notification.newNotification;
            if(newNotification?.id  && newNotification?.data.newSubmissionData) {
              const newSubmissionData = new Submission().deserialize(JSON.parse(newNotification.data.newSubmissionData));
              this.submissions.forEach((sub) => {
if(sub.id === newSubmissionData.id) {
sub = newSubmissionData;
}
});
            }
          }, error: (error: Error) => {
            console.log(error);
          }
    });
  }

  public openSubmission(sub: Submission, allSubmissions: Submission[]): void {
    const dialogRef = this.dialog.open(SubmissionDialogComponent, {
      width: '656px',
      height: '720px',
      data: {
        allSubmissions,
        submission: sub,
        creators: this.approvedCreators.map(value => value.creator),
      },
    });

    dialogRef.afterClosed()
    .subscribe({
      next : async () => {
        this.updateSubmissions(this.creatorSelected.id);
      },
      error: (error: Error) => console.log(error)});
  }

  private setSubmissionsForCreator(creatorId: number): void {
    this.creatorSubmissions = this.submissions.filter(sub => sub.userId === creatorId);
    this.sortSubmissions();
  }

  public sortSubmissions(): void {
    this.approved = this.creatorSubmissions?.filter( x => x.status === SubmissionState.Approved);
    this.rejected = this.creatorSubmissions?.filter( x => x.status === SubmissionState.Rejected);
    this.pending = this.creatorSubmissions?.filter( x => x.status === SubmissionState.Requested);
    this.updateRequired = this.creatorSubmissions?.filter( x => x.status === SubmissionState.UpdateRequired);
  }

  public afterDelete($event){
    this.submissions = $event;
    this.creatorSubmissions = $event;
    this.sortSubmissions();
  }

  public updateSubmissions(creatorId: number): void {
    if(creatorId) {
this.setSubmissionsForCreator(creatorId);
} else {
      this.creatorSubmissions = this.submissions;
      this.sortSubmissions();
    }
  }

  public tabOpenTrigger(event: string){
    if(event === 'open'){
      this.allOpen.next(true);
    } else {
      this.allOpen.next(false);
    }
  }

  public selectedCreator($event: any): void {
    if($event !== 'All Creators') {
      this.creatorSelected = $event;
      this.updateSubmissions($event.id);
    } else {
      this.updateSubmissions(null);
    }
  }
}

import { Component, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import localePt from '@angular/common/locales/pt';
import { MessagingService } from './services/messaging.service';
import { Config } from 'protractor';
import { ConfigBackend } from './models/configBackend.model';
import { AppService } from './services/app.service';
import { LocalStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    translate: TranslateService,
    private messagingService: MessagingService,
    private localStorage: LocalStorageService,
    private appService: AppService) {

    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('es');

    translate.addLangs(['en', 'es', 'pt']);

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    const lang = this.localStorage.retrieve('lang');
    if(lang) {
      translate.use(this.localStorage.retrieve('lang'));
    } else {
      const newLang = navigator.language.split('-')[0];
      this.localStorage.store('lang', newLang);
      translate.use(newLang);
    }

    registerLocaleData(localeEs);
    registerLocaleData(localePt);
  }

  async ngOnInit() {
    document.getElementById('app_root_preloader').remove();
    // this.messagingService.requestPermission();
    this.messagingService.listenMessages();
    this.messagingService.listenBackgroundMessages();
    this.appService.getConfigBackend();
  }
}

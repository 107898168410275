import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SocialNetworksIconsComponent } from './social-networks-icons/social-networks-icons.component';
import { CreatorSelectComponent } from './creator-select/creator-select.component';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SuggestedPriceTabsComponent } from './suggested-price-tabs/suggested-price-tabs.component';
import { GenderFollowersChartComponent } from './gender-followers-chart/gender-followers-chart.component';
import { GenderChartDoughnutComponent } from './gender-chart-doughnut/gender-chart-doughnut.component';
import { SentimentChartDoughnutComponent } from './sentiment-chart-doughnut/sentiment-chart-doughnut.component';

@NgModule({
  declarations: [
    SocialNetworksIconsComponent,
    CreatorSelectComponent,
    SuggestedPriceTabsComponent,
    GenderFollowersChartComponent,
    GenderChartDoughnutComponent,
    SentimentChartDoughnutComponent
  ],
  imports: [CommonModule, MatSelectModule, FormsModule, ReactiveFormsModule],
  exports: [
    SocialNetworksIconsComponent,
    CreatorSelectComponent,
    SuggestedPriceTabsComponent,
    GenderFollowersChartComponent,
    GenderChartDoughnutComponent,
    SentimentChartDoughnutComponent
  ],
})
export class ComponentModule {}

import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { Chart } from 'chart.js';
import { AudienceSentiments } from 'src/app/models/tiktok.model';
import { options } from './sentiment-chart-doughnut.config';

@Component({
  selector: 'app-sentiment-chart-doughnut',
  templateUrl: './sentiment-chart-doughnut.component.html',
  styleUrls: ['./sentiment-chart-doughnut.component.css']
})
export class SentimentChartDoughnutComponent implements OnInit {
  public chart: Chart<'doughnut', number[], string>;
  @Input() public labels: string[];
  @Input() public sentiment: AudienceSentiments;

  constructor(private elementRef: ElementRef) { }

  ngOnInit(): void {
    console.log(this.sentiment);
    if(this.sentiment) {
        this.createChart();
    }
  }

  createChart(): void {
    const htmlRef = this.elementRef.nativeElement.querySelector(`#sentimentChart`);
    this.chart = new Chart(htmlRef, {
      type: 'doughnut',
      data: {
        labels: ['POSITIVE', 'NEGATIVE', 'NEUTRAL'],
        datasets: [
          {
            data: [this.sentiment.POSITIVE.prc, this.sentiment.NEGATIVE.prc, this.sentiment.NEUTRAL.prc],
            borderWidth: 0.1,
            backgroundColor: [
              '#44e36e',
              '#ff0000',
              '#C5C5C5'
            ]
          },
        ]
      },
      options
    });
  }

}

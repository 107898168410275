import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout/layout.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from '../app-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { NotificationCardComponent } from './footer/notification/notification-card/notification-card.component';
import { NotificationsSideBarComponent } from './footer/notification/notifications-side-bar/notifications-side-bar.component';
import { NotificationButtonComponent } from './footer/notification/notification-button/notification-button.component';
import { NotificationComponent } from './footer/notification/notification.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [LayoutComponent, HeaderComponent, FooterComponent, NotificationComponent, NotificationButtonComponent, NotificationCardComponent, NotificationsSideBarComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    AppRoutingModule,
    MatMenuModule,
    MatIconModule,
    TranslateModule,
    ScrollingModule
  ],
  exports: [HeaderComponent]
})
export class UiModule {
}
